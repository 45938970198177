import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RequireAuth from './Components/AuthenticationComponents/RequireAuth';
import UnauthorizedScreen from './Screens/UnauthorizedScreen';
import PageNotFound from './Screens/PageNotFound';
import './output.css';  // Ensure Tailwind CSS is imported

const WelcomeLayoutScreen = lazy(() => import('./Screens/WelcomeLayoutScreen'));
const WelcomeScreen = lazy(() => import('./Screens/WelcomeScreen'));
const BuyerSellerDetailScreen = lazy(() => import('./Components/WelcomeScreenComponent/WelcomeScreenChildrenComponent/BuyerSellerDetailScreen'));
const ValueAddedDetailScreen = lazy(() => import('./Components/WelcomeScreenComponent/WelcomeScreenChildrenComponent/ValueAddedDetailScreen'));
const MainScreen = lazy(() => import('./Screens/MainScreen'));
const HomeScreen = lazy(() => import('./Screens/HomeScreen'));
const ProfileScreen = lazy(() => import('./Screens/ProfileScreen'));
const AddProductScreen = lazy(() => import('./Screens/AddProductScreen'));
const LoginScreen = lazy(() => import('./Screens/LoginScreen'));
const ProductViewScreen = lazy(() => import('./Screens/ProductViewScreen'));
const RequirementProductViewScreen = lazy(() => import('./Screens/RequirementProductViewScreen'));
const OrderHistoryScreen = lazy(() => import('./Screens/OrderHistoryScreen'));
const RecivedOrderScreen = lazy(() => import('./Screens/RecivedOrderScreen'));
const Invoice = lazy(() => import('./Components/Invoice'));
const CropwayGuide = lazy(() => import('./Screens/CropwayGuide'));
const ChooseProductCategoryScreen = lazy(() => import('./Screens/ChooseProductCategoryScreen'));
const SelectProductForAuction = lazy(() => import('./Screens/Auction/SelectProductForAuction'));
const AddToAuctionScreen = lazy(() => import('./Screens/Auction/AddToAuctionScreen'));
const ManageAuctionScreen = lazy(() => import('./Screens/Auction/ManageAuctionScreen'));
const AuctionViewScreen = lazy(() => import('./Screens/Auction/AuctionViewScreen'));
const ProductListScreen = lazy(() => import('./Screens/ProductListScreen'));
const ManageUserScreen = lazy(() => import('./Screens/ManageUserScreen'));
const AddNewUserScreen = lazy(() => import('./Screens/AddNewUserScreen'));

function App() {
  return (
    <div className='App p-1'>
      <Router>
        <Suspense fallback={<div className="flex justify-center items-center h-screen">Loading...</div>}>
          <Routes>
            {/* Public routes */}
            <Route path='/' element={<WelcomeLayoutScreen />}>
              <Route index element={<WelcomeScreen />} />
              <Route path='aboutbuyer' element={<BuyerSellerDetailScreen />} />
              <Route path='aboutseller' element={<BuyerSellerDetailScreen />} />
              <Route path='aboutvas' element={<ValueAddedDetailScreen />} />
            </Route>

            <Route path='login' element={<LoginScreen />} />
            <Route path='unauthorized' element={<UnauthorizedScreen />} />

            {/* Protected routes */}
            <Route element={<RequireAuth />}>
              <Route path='dashboard' element={<MainScreen />}>
                <Route index element={<HomeScreen />} />
                <Route path='profile' element={<ProfileScreen />} />
                <Route path='chooseproductcategory'>
                  <Route index element={<ChooseProductCategoryScreen />} />
                  <Route path='addproduct' element={<AddProductScreen />} />
                </Route>
                <Route path='orderhistory'>
                  <Route index element={<OrderHistoryScreen />} />
                  <Route path='recivedorder' element={<RecivedOrderScreen />} />
                  <Route path='invoice' element={<Invoice />} />
                </Route>
                <Route path='cropwayguide' element={<CropwayGuide />} />
                <Route path='auction'>
                  <Route index element={<ManageAuctionScreen />} />
                  <Route path='selectproductforauction'>
                    <Route index element={<SelectProductForAuction />} />
                    <Route path='addtoauction' element={<AddToAuctionScreen />} />
                    <Route path='auctionview' element={<AuctionViewScreen />} />
                  </Route>
                </Route>
                <Route path='productlist'>
                  <Route index element={<ProductListScreen />} />
                  <Route path='editlistedproduct' element={<AddProductScreen />} />
                </Route>
                <Route path='manageuserscreen'>
                  <Route index element={<ManageUserScreen />} />
                  <Route path='addnewuser' element={<AddNewUserScreen />} />
                </Route>
                <Route path='productview' element={<ProductViewScreen />} />
                <Route path='requirementproductview' element={<RequirementProductViewScreen />} />
                <Route path='recivedorder' element={<RecivedOrderScreen />} />
                <Route path='auctionview' element={<AuctionViewScreen />} />
              </Route>
            </Route>

            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
